import * as React from "react";
import { Link, HeadFC } from "gatsby";
import pageNotFound from "../images/page404.png";
import bg500 from "../images/bg-500.png";
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};
const listButtons = [
  { label: "Homepage", link: "/" },
  { label: "Search", link: "/search/" },
  { label: "Contact Us", link: "/contact" },
];
const NotFoundPage = () => {
  return (
    <main className="flex flex-col items-center  justify-center bg-banner-404 bg-cover bg-center fixed z-0 w-full h-full pb-8">
      <div className="flex flex-col items-center  justify-center  ">
        <img
          src={pageNotFound}
          alt="page not found"
          className="w-8/12 max-w-2xl sm:mt-10"
        />
        <p className=" text-[18px] lg:text-[24px] font-light ff-cg--medium mt-10 text-center">
          It looks like the page you were trying to access doesn't exist or has
          been removed.
          {/* <br />
       {process.env.NODE_ENV === "development" ? (
         <>
           <br />
           Try creating a page in <code style={codeStyles}>src/pages/</code>.
           <br />
         </>
       ) : null}
       <br /> */}
        </p>
        <p className="text-[18px] lg:text-[24px] font-light ff-cg--semibold">
          Here are some helpful links instead:
        </p>
        <div className="flex flex-col sm:flex-row gap-x-6">
          {listButtons.map((button) => (
            <Link
              to={button.link}
              className="w-full md:w-fit flex flex-col items-center justify-between border solid border-black  rounded-2xl mt-[20px] ff-cg--bold leading-none text-[18px] px-9 py-5"
            >
              {button.label}
            </Link>
          ))}
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
